@import '../../style/themes/default';
@import '../../style/mixins/index';

@full-calendar-prefix-cls: ~'@{ant-prefix}-fullcalendar';

.@{full-calendar-prefix-cls} {
  .reset-component;

  border-top: @border-width-base @border-style-base @border-color-base;
  outline: none;

  .@{ant-prefix}-select&-year-select {
    min-width: 90px;

    &.@{ant-prefix}-select-sm {
      min-width: 70px;
    }
  }

  .@{ant-prefix}-select&-month-select {
    min-width: 80px;
    margin-left: 8px;

    &.@{ant-prefix}-select-sm {
      min-width: 70px;
    }
  }

  &-header {
    padding: 11px 16px 11px 0;
    text-align: right;

    .@{ant-prefix}-select-dropdown {
      text-align: left;
    }

    .@{ant-prefix}-radio-group {
      margin-left: 8px;
      text-align: left;
    }

    label.@{ant-prefix}-radio-button {
      height: 22px;
      padding: 0 10px;
      line-height: 20px;
    }
  }

  &-date-panel {
    position: relative;
    outline: none;
  }

  &-calendar-body {
    padding: 8px 12px;
  }

  table {
    width: 100%;
    max-width: 100%;
    height: 256px;
    background-color: transparent;
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: 0;
  }

  td {
    position: relative;
  }

  &-calendar-table {
    margin-bottom: 0;
    border-spacing: 0;
  }

  &-column-header {
    width: 33px;
    padding: 0;
    line-height: 18px;
    text-align: center;
    .@{full-calendar-prefix-cls}-column-header-inner {
      display: block;
      font-weight: normal;
    }
  }

  &-week-number-header {
    .@{full-calendar-prefix-cls}-column-header-inner {
      display: none;
    }
  }

  &-month,
  &-date {
    text-align: center;
    transition: all 0.3s;
  }

  &-value {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    padding: 0;
    color: @text-color;
    line-height: 24px;
    background: transparent;
    border-radius: @border-radius-sm;
    transition: all 0.3s;

    &:hover {
      background: @item-hover-bg;
      cursor: pointer;
    }

    &:active {
      color: @text-color-inverse;
      background: @primary-color;
    }
  }

  &-month-panel-cell &-value {
    width: 48px;
  }

  &-today &-value,
  &-month-panel-current-cell &-value {
    box-shadow: 0 0 0 1px @primary-color inset;
  }

  &-selected-day &-value,
  &-month-panel-selected-cell &-value {
    color: @text-color-inverse;
    background: @primary-color;
  }

  &-disabled-cell-first-of-row &-value {
    border-top-left-radius: @border-radius-base;
    border-bottom-left-radius: @border-radius-base;
  }

  &-disabled-cell-last-of-row &-value {
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
  }

  &-last-month-cell &-value,
  &-next-month-btn-day &-value {
    color: @disabled-color;
  }

  &-month-panel-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
  }

  &-content {
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 100%;
  }

  &-fullscreen {
    border-top: 0;
  }

  &-fullscreen &-table {
    table-layout: fixed;
  }

  &-fullscreen &-header {
    .@{ant-prefix}-radio-group {
      margin-left: 16px;
    }
    label.@{ant-prefix}-radio-button {
      height: @input-height-base;
      line-height: @input-height-base - 2px;
    }
  }

  &-fullscreen &-month,
  &-fullscreen &-date {
    display: block;
    height: 116px;
    margin: 0 4px;
    padding: 4px 8px;
    color: @text-color;
    text-align: left;
    border-top: 2px solid @border-color-split;
    transition: background 0.3s;

    &:hover {
      background: @item-hover-bg;
      cursor: pointer;
    }

    &:active {
      background: @primary-2;
    }
  }

  &-fullscreen &-column-header {
    padding-right: 12px;
    padding-bottom: 5px;
    text-align: right;
  }

  &-fullscreen &-value {
    width: auto;
    text-align: right;
    background: transparent;
  }

  &-fullscreen &-today &-value {
    color: @text-color;
  }

  &-fullscreen &-month-panel-current-cell &-month,
  &-fullscreen &-today &-date {
    background: transparent;
    border-top-color: @primary-color;
  }

  &-fullscreen &-month-panel-current-cell &-value,
  &-fullscreen &-today &-value {
    box-shadow: none;
  }

  &-fullscreen &-month-panel-selected-cell &-month,
  &-fullscreen &-selected-day &-date {
    background: @primary-1;
  }

  &-fullscreen &-month-panel-selected-cell &-value,
  &-fullscreen &-selected-day &-value {
    color: @primary-color;
  }

  &-fullscreen &-last-month-cell &-date,
  &-fullscreen &-next-month-btn-day &-date {
    color: @disabled-color;
  }

  &-fullscreen &-content {
    position: static;
    width: auto;
    height: 88px;
    overflow-y: auto;
  }

  &-disabled-cell &-date {
    &,
    &:hover {
      cursor: not-allowed;
    }
  }

  &-disabled-cell:not(&-today) &-date {
    &,
    &:hover {
      background: transparent;
    }
  }

  &-disabled-cell &-value {
    width: auto;
    color: @disabled-color;
    border-radius: 0;
    cursor: not-allowed;
  }
}
